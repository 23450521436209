import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState, } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from './core/reducers';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { PartialsModule } from '@shared/partials/partials.module';
import { LayoutConfig } from './core/_config/layout.config';

import { ConfirmationDialogComponent } from '@shared/partials/content/crud/confirm-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from '@shared/partials/content/crud/confirm-dialog/confirmation-dialog.service';

import { AuthModule } from '@coreauth/auth.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ApiInterceptor } from '@coreauth/interceptors/api.interceptor';
import {
  DataTableService,
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
} from '@corebase/layout';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ApiUtilsService } from '@coreservices/api/api-utils.service';
import { FormlyformModule } from '@shared/formlyform';
import { CurrencyPipe } from '@angular/common';

// initialize app by loading default demo layout config
export const initializeLayoutConfig = (appConfig: LayoutConfigService) => () => {
  if (appConfig.getConfig() === null) {
    appConfig.loadConfigs(new LayoutConfig().configs);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationDialogComponent,
  ],
  exports: [ConfirmationDialogComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HammerModule,
    AppRoutingModule,
    PartialsModule,
    CoreModule,
    OverlayModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router', routerState: RouterState.Minimal }),
    StoreDevtoolsModule.instrument(),
    AuthModule.forRoot(),
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    NgxDaterangepickerMd.forRoot({
      separator: ' - ',
      format: 'MMM DD, YYYY',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel'
    }),
    FormlyformModule,
  ],
  providers: [
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    DataTableService,
    ConfirmationDialogService,
    SplashScreenService,
    CurrencyPipe,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService], multi: true
    },
    MenuHorizontalService,
    MenuAsideService,
    ApiUtilsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }
}
