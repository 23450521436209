import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { locale as enLang } from '@core/_config/i18n/en';
import { locale as chLang } from '@core/_config/i18n/ch';
import { locale as esLang } from '@core/_config/i18n/es';
import { locale as jpLang } from '@core/_config/i18n/jp';
import { locale as deLang } from '@core/_config/i18n/de';
import { locale as frLang } from '@core/_config/i18n/fr';
import { TranslationService, LayoutConfigService, SplashScreenService, } from '@core/base/layout';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Metronic';
  loader?: boolean;
  private unsubscribe: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
  ) {

    this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
  }

  ngOnInit(): void {
    this.loader = this.layoutConfigService.getConfig('loader.enabled');

    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.splashScreenService.hide();
        window.scrollTo(0, 0);
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
