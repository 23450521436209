<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{ message }}
</div>
<div class="modal-footer">
  <button
    type="button"
    *ngIf="btnType === 'yesno'"
    class="btn btn-default m-1 pr-5 pl-5"
    (click)="decline()"
  >
    {{ btnCancelText }}
  </button>
  <button
    type="button"
    class="btn btn-instagram m-1 pr-5 pl-5"
    (click)="accept()"
  >
    {{ btnOkText }}
  </button>
</div>
