// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      EMPLOYEES: 'Employees',
      CLIENTS: 'Clients',
      ORDERS: 'Orders',
      SCAN: 'Scan',
      BATCHES: 'Batches',
      HISTORY: 'Scan History',
      OPENS: 'Opens',
      CANCELLED: 'Cancelled',
      ONHOLD: 'On-hold',
      SHIPPED: 'Shipped',
      SETTINGS: 'Settings',
      ADMINUSER: 'Admin Users',
      ACTIONLOGS: 'Action Logs',
      PRODUCTS: 'Products',
      RESYNC: 'Resync Data',
      PRINTERS: {
        LOG: 'Log',
        ARTWORKS: 'Artworks',
        QUEUE: 'Queue'
      },
      ANALYTICS: {
        EMPLOYEES: 'Employees',
        SIZES: 'Sizes'
      }
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        SAVE_BUTTON: 'Save',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      RESET: {
        TITLE: 'Reset Password',
        SUCCESS: 'Your password has been successfully reset.',
        FAIL: 'The reset password request failed'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your password to register your account',
        SUCCESS: 'Your account has been successfully registered.'
      },
      UPDATE: {
        TITLE: 'Settings',
        DESC: 'Enter your details to update your account',
        SUCCESS: 'Your details has been successfully updated.',
        FAIL: 'Updating your details has been failed.',
        NOCHANGE: 'There are no changes'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
        NAME: 'Name',
        PAYMENTEMAIL: 'Payment Email',
        PAYMENTMETHOD: 'Payment method',
        PAYPAL: 'Paypal',
        PAYONEER: 'Payoneer',
        BANK: 'Bank Transfer',
        ACCOUNTNAME: 'Account Name',
        ACCOUNTNUMBER: 'Account Number',
        ROUTINGNUMBER: 'Routing Number',
        ROYALTY: 'Royalty',
        AGENCY: 'Agency',
        PRODROLE: 'Production Role',
        ROLE: 'User Role',
        STATION_TYPE: 'User Station Type'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        MAX_VALUE: 'Maximum value: ',
        MIN_VALUE: 'Minimum value: ',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    PAYMENT: {
      INPUT: {
        ARTISTNAME: 'Artist Name',
        CONFIRMATIONID: 'Confirmation ID',
        TRANSACTIONID: 'Transaction ID',
        DESCRIPTION: 'Description'
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    }
  }
};
